import isString from '@commons/utils/string/isString';
import removeSpecialChars from '@commons/utils/string/removeSpecialChars';

export function removeCharsExceptLetters(str) {
  const string = removeSpecialChars(str);
  return string.replace(/[\(\)]/gi, '').replace(/[^a-zA-Z ]/g, '');
}

export function capitalize(str) {
  if (str.length <= 1) return str.toUpperCase();
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function joinAsSentence(stringArray) {
  return stringArray.join(', ').replace(/,(?!.*,)/gim, ' y');
}

export function isEmpty(value) {
  const valueTrimed = value && isString(value) && value.trim();
  return !valueTrimed;
}

export function firstCharToUpperCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function padString(string, pad) {
  return (pad + string.toString()).slice(-pad.length);
}

export function removeSpaces(str) {
  return str.replace(/\s/g, '');
}

export function isValidOpinion(string) {
  const strNoSpaces = removeSpaces(string);
  return string.length !== 0 && strNoSpaces.length < 1;
}
