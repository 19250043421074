import React, { useEffect, useState } from 'react';
import { Text } from '@pedidosya/order-status-components';
import { usePublicEnv } from '@app/providers/PublicEnv';
import isObject from '@commons/utils/object/isObject';
import styled from 'styled-components';
import messages from './messages';
import { useTranslate } from '@app/providers/I18nProvider';
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ToggleErrorMessage = ({ message, toggleMessage }) => {
  const DEFAULT_TOGGLE_MESSAGE = 'there is no message';
  const CLICKS_TO_TOGGLE_MESSAGE = 10;
  const [count, setCount] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(message);

  useEffect(() => {
    if (count >= CLICKS_TO_TOGGLE_MESSAGE) {
      setCurrentMessage(toggleMessage || DEFAULT_TOGGLE_MESSAGE);
      setCount(0);
    } else if (
      count >= 1 &&
      (currentMessage === toggleMessage || currentMessage === DEFAULT_TOGGLE_MESSAGE)
    ) {
      setCurrentMessage(message);
    }
  }, [count, currentMessage, message, toggleMessage]);

  const increaseCounter = () => {
    setCount((prevCount) => prevCount + 1);
  };

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <span onClick={increaseCounter}>{currentMessage}</span>;
};

const MicrositeError = ({ debugMessage, ...props }) => {
  const { translate } = useTranslate();
  const context = usePublicEnv();
  const isDebugEnabled = isObject(context) && context?.debugMode ? true : false;
  const subtitle =
    isDebugEnabled && debugMessage
      ? debugMessage
      : props.subtitle || translate(messages.loadCardsError);
  return (
    <ErrorContainer>
      <Text>{subtitle}</Text>
      <ToggleErrorMessage message={''} toggleMessage={debugMessage} />
    </ErrorContainer>
  );
};

export default MicrositeError;
