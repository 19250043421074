import ORDER_TYPE from '@app/constants/order-type';
import { isEmpty } from '../string';
import messages from './messages';

const MESSAGES = {
  [ORDER_TYPE.OWN_DELIVERY]: messages.ownDelivery,
  [ORDER_TYPE.VENDOR_DELIVERY]: messages.vendorDelivery,
  [ORDER_TYPE.PICKUP]: messages.pickUp,
};
function getDeliveryOrderMessageTranslationKeyByType(orderDeliveryType) {
  return !isEmpty(orderDeliveryType) ? MESSAGES[orderDeliveryType] ?? null : null;
}

export default getDeliveryOrderMessageTranslationKeyByType;
