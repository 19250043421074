const ORDER_STATE = {
  REJECTED: 'REJECTED',
  CONFIRMED: 'CONFIRMED',
  DISPATCHED: 'DISPATCHED',
  ARRIVED: 'ARRIVED',
  PENDING: 'PENDING',
  WAITING_FOR_TRANSPORT: 'WAITING_FOR_TRANSPORT',
  ORIGIN_ARRIVED: 'ORIGIN_ARRIVED',
  CANCELLED: 'ABORTED',
  ENABLED: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

export default ORDER_STATE;
