import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GlobalStyleSheet from '@appscore/web-components/theme/globalStyleSheet';
import { ThemeProvider as SCThemeProvider, createGlobalStyle } from 'styled-components';
import { ThemeProvider as OSThemeProvider } from '@pedidosya/order-status-components';

function mapTheme(_theme) {
  if (!_theme) {
    return _theme;
  }
  const mappedTheme = JSON.parse(JSON.stringify(_theme).replace(/"Muli"/g, '"Mulish"'));
  mappedTheme.spacing = _theme.spacing;
  return _theme;
}

const CustomGlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
  }
`;

const themeContext = createContext(null);

const ThemeProvider = ({ children, theme }) => {
  const [activeTheme] = useState(mapTheme(theme));
  const state = useMemo(() => ({ theme: activeTheme }), [activeTheme]);

  return (
    <themeContext.Provider value={state}>
      <>
        <OSThemeProvider theme={state.theme}>
          <SCThemeProvider theme={state.theme}>
            <>
              <GlobalStyleSheet />
              <CustomGlobalStyle />
              {children}
            </>
          </SCThemeProvider>
        </OSThemeProvider>
      </>
    </themeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

export const useTheme = () => useContext(themeContext);

export default ThemeProvider;
