import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from '@app/utils/string';

function ReviewPendingOrderCardTracking({ tracker, order, children, ...props }) {
  useEffect(() => {
    if (tracker && !Number.isNaN(Number(order?.id)) && !isEmpty(order?.type)) {
      tracker?.track('survey.loaded', {
        orderId: order.id,
        deliveryProvider: order.type,
        feedbackType: 'rider',
      });
    }
  }, [tracker, order]);

  return children({ order, ...props });
}

ReviewPendingOrderCardTracking.propTypes = {
  tracker: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default ReviewPendingOrderCardTracking;
