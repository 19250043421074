import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const PillShape = styled.div`
  font-family: 'Segma';
  line-height: 16px;
  text-transform: uppercase;
  font-size: 11px;
  background: rgba(239, 237, 240, 0.3);
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 12px 2px 8px;
  border-radius: 4px 12px 12px 4px;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const Container = styled.div`
  height: 18px;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.corporateWhite_20};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

function Pill({ children, ...props }) {
  return (
    <Container>
      <PillShape {...props}>{children}</PillShape>
    </Container>
  );
}

Pill.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Pill;
