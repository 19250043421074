import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { isServer } from '@commons/utils';
import { getWindowObject } from '@app/utils/documentWindow';

export const WindowContext = createContext(null);

function WindowProvider({ children }) {
  // Set default to null to prevent server html differ from hydrate html.
  const [w, setWindow] = useState(null);

  const fromServer = isServer();
  useEffect(() => {
    if (!fromServer) setWindow(getWindowObject);
  }, [fromServer]);

  return <WindowContext.Provider value={w}>{children}</WindowContext.Provider>;
}

export const useWindow = () => useContext(WindowContext);

WindowProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowProvider;
