import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import App from './App';
import 'core-js/modules/es.promise';

import MicrositeInitializer from './components/Microsite/MicrositeInitializer';

const Root = (shellData) => {
  const trackerPromise = System.import('@tracker').catch(() => {});
  const fwfPromise = System.import('@fwf').catch(() => {});
  return (
    <MicrositeInitializer
      shellData={{ ...shellData, fwfPromise, trackerPromise }}
      AppComponent={App}
    />
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.object,
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
