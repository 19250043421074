import React from 'react';
import SliderSkeleton from '@app/components/ui/molecules/SliderSkeleton';

// import styled from 'styled-components';

// const LoaderContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   min-height: 100vh;
// `;

const MicrositeLoader = () => {
  return <SliderSkeleton />;
  // return (
  //   <LoaderContainer>
  //     <BrandLoader />
  //   </LoaderContainer>
  // );
};

export default MicrositeLoader;
