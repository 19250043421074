import React from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@app/logger';

class ErrorBoundary extends React.Component {
  state = { showComponent: true };

  isChunkLoadingError(error) {
    return (
      error?.message.indexOf('Loading chunk') !== -1 ||
      error?.message.indexOf('Loading CSS chunk') !== -1
    );
  }

  componentDidCatch(error) {
    if (!this.isChunkLoadingError(error)) {
      this.setState({
        showComponent: false,
      });

      this.log(error);
    }
  }

  log(error) {
    getLogger().error(`[APP_ERROR] msg: ${JSON.stringify(error)}`);
  }

  render() {
    const { showComponent } = this.state;
    const { children, fallback } = this.props;
    return showComponent ? children : fallback || null;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default ErrorBoundary;
