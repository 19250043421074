import { getFWFToken, getFWFUrl } from './config';
import { getEnvironment, isProduction, isLocal } from '@app/utils/environment';
import { Flags } from '@commons/fwf/models/Flags';

import { FLAGS_KEYS } from './constants';

const ENV = getEnvironment();
const FWF_TOKEN = getFWFToken(ENV);
const FWF_URL = getFWFUrl(ENV);
const FLAG_TTL_IN_MINUTES = isProduction() ? 20 : 1;
const FLAGS_UPDATED_EVENT_NAME = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';
const CONTEXT_UPDATED_EVENT_NAME = '@fwf/RELEVANT_CONTEXT_CHANGE';
// change manualy to true to enable SDK debug mode
const DEBUG_MODE = isLocal() && false;

const fwfParams = {
  environmentToken: FWF_TOKEN,
  flagTimeToLive: FLAG_TTL_IN_MINUTES,
  region: 'us',
  localFwfUrl: FWF_URL,
  debugMode: DEBUG_MODE,
};

let fwfInstance = null;

function getUser({ sessionId, userData = {}, contextData = {} }) {
  let user = {
    ...contextData,
  };
  if (sessionId) user.googleClientId = sessionId;
  const { userId, userEmail, ...userDataRest } = userData;
  if (userId) user.userId = userId.toString();
  if (userEmail) user.email = userEmail;
  if (userId || userEmail) user = { ...user, ...userDataRest };
  return user;
}

function importSdk() {
  return import(/* webpackChunkName: "fwf-sdk" */ '@fwf/fwf-sdk-javascript');
}

export async function initializeInstance(
  fwfPromise,
  { sessionId, userData, contextData },
  onUpdate,
) {
  fwfInstance = await fwfPromise;

  const user = getUser({ sessionId, userData, contextData });
  fwfInstance.setContext(user);
  fwfInstance.subscribeFeatures(FLAGS_KEYS);
  fwfInstance.subscribeObserver((response) => {
    if (
      response.type === FLAGS_UPDATED_EVENT_NAME ||
      response.type === CONTEXT_UPDATED_EVENT_NAME
    ) {
      const flags = Flags(response.data);
      if (typeof onUpdate === 'function') {
        onUpdate(flags);
      }
    }
  });

  return fwfInstance;
}

export async function getServerInstance({ sessionId, userData, contextData = {} }) {
  const user = getUser({ sessionId, userData, contextData });
  const { default: FWFMain } = await importSdk();
  return new FWFMain({ ...fwfParams, user });
}

export async function getClientInstance() {
  if (!fwfInstance) throw new Error('[FWF] must call initializeInstance before get one');

  return fwfInstance;
}

export async function updateUser({ sessionId, userData, contextData = {} }) {
  const instance = await getClientInstance();
  const user = getUser({ sessionId, userData, contextData });
  return instance.setContext(user);
}
