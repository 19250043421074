import { isEmpty } from '@app/utils/string';

export function getOrderIncident(step, incidentCode) {
  if (step && Array.isArray(step?.incidents) && !isEmpty(incidentCode)) {
    const code = incidentCode.trim().toLowerCase();

    const shearchedIncident = step?.incidents
      .filter((incident) => incident && !isEmpty(incident.code))
      .find((incident) => incident.code.toLowerCase() === code);

    return shearchedIncident || null;
  }

  return null;
}
