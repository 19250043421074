import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import UserOrderCardsPage from './pages/UserOrderCardsPage/UserOrderCardsPage';

function Router({ tracker, context, isDesktop, isHomeHelpCenterAccessEnabled }) {
  const { search } = useLocation();
  const queryParams = React.useMemo(() => {
    const params = new URLSearchParams(search);
    const result = [];
    for (var [key, value] of params.entries()) {
      result[key] = value;
    }
    return result;
  }, [search]);

  return (
    <Switch>
      <Route
        render={(props) => (
          <UserOrderCardsPage
            queryParams={queryParams}
            tracker={tracker}
            isDesktop={isDesktop}
            isHomeHelpCenterAccessEnabled={isHomeHelpCenterAccessEnabled}
            context={context}
            {...props}
          />
        )}
      />
    </Switch>
  );
}

Router.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool,
  isHomeHelpCenterAccessEnabled: PropTypes.bool,
};

export default Router;
