const ORDER_TYPE = {
  LOGISTIC: 'LOGISTIC',
  MARKETPLACE: 'MARKETPLACE',
  DISPATCH: 'DISPATCH',
  OWN_DELIVERY: 'OWN_DELIVERY',
  VENDOR_DELIVERY: 'VENDOR_DELIVERY',
  PICKUP: 'PICKUP',
  COURIER: 'COURIER',
};

export default ORDER_TYPE;
