import { defineMessages } from 'react-intl';

export default defineMessages({
  vendorDelivery: {
    id: 'app.components.DeliveryPill.vendorDelivery',
    defaultMessage: 'Entrega del local',
  },
  ownDelivery: {
    id: 'app.components.DeliveryPill.ownDelivery',
    defaultMessage: 'entrega',
  },
  pickUp: {
    id: 'app.components.DeliveryPill.pickUp',
    defaultMessage: 'Retiro en el local',
  },
});
