/* eslint-disable no-console */
import applicationVersion from '@commons/constants/applicationVersion';
import { isLocal, isTest } from '@app/utils/environment';

import { datadogLogs } from '@datadog/browser-logs';
import { isEmpty } from '@app/utils/string';
import { LOG_GROUP } from '@app/constants/logger';

let logger = {
  info(message, context = null) {
    console.log(message, context);
  },
  warn(message, context = null) {
    console.warn(message, context);
  },
  debug(message, context = null) {
    console.log(message, context);
  },
  error(message, context = null) {
    console.error(message, context);
  },
};

let logPrefix = '';

function initLogger(environment) {
  const datacenterUS = 'datadoghq.com';
  const datadogClientToken = 'pub3c568d433b837b1cec4a29b787e011a7';
  const version = applicationVersion;
  let logLevel = 'info';
  let logHandlers = ['console', 'http'];
  logger = {
    logger: datadogLogs.logger,
    init() {
      datadogLogs.init({
        clientToken: datadogClientToken,
        site: datacenterUS,
        forwardErrorsToLogs: true,
        sampleRate: 15,
        service: 'client-logs-order-status-parcel-microsite',
        version,
        env: environment,
        silentMultipleInit: true,
      });

      if (isLocal() || isTest()) {
        logLevel = 'debug';
        logHandlers = ['console'];
      }

      datadogLogs.logger.setLevel(logLevel);
      datadogLogs.logger.setHandler(logHandlers);

      datadogLogs.setLoggerGlobalContext({
        env: environment,
        domain: window.location.hostname,
      });

      this.debug(`initializing datadog logger`, {
        version,
        level: logLevel,
        handlers: logHandlers,
      });
    },
    setContext(context) {
      const globalContext = datadogLogs.getLoggerGlobalContext();
      datadogLogs.setLoggerGlobalContext({
        ...globalContext,
        ...context,
      });
    },
    addContext({ key, value }) {
      datadogLogs.addLoggerGlobalContext(key, value);
    },
    setLogsPrefix(prefix) {
      logPrefix = prefix || '';
    },
    info(message, context = null) {
      this.logger.info(`${logPrefix}${message}`, context);
    },
    warn(message, context = null) {
      this.logger.warn(`${logPrefix}${message}`, context);
    },
    debug(message, context = null) {
      this.logger.debug(`${logPrefix}${message}`, context);
    },
    error(message, context = null) {
      this.logger.error(`${logPrefix}${message}`, context);
    },
  };

  logger.init();
}

async function createLoggerInstance(
  {
    environment,
    tokenType = null,
    countryId = null,
    countryShortName = null,
    origin = null,
    appVersion,
    platformKey,
    locale,
    device,
  },
  prefix = LOG_GROUP,
) {
  return new Promise((resolve, reject) => {
    if (!isEmpty(environment)) {
      try {
        initLogger(environment);

        const loggerContext = {
          environment,
          tokenType,
          countryId,
          countryShortName,
          origin,
          appVersion,
          platformKey,
          locale,
          device,
        };

        getLogger().setContext(loggerContext);
        getLogger().setLogsPrefix(prefix);
        resolve();
      } catch (e) {
        reject(new Error(`logger initialization has failed: ${e.message}`));
      }
    } else {
      reject(new Error(`the context environment value is not valid`));
    }
  });
}

function getLogger() {
  return logger;
}

export { createLoggerInstance, getLogger };
