export function getLocale({ language, shortName } = {}) {
  return language && shortName
    ? `${language}-${shortName.toUpperCase()}`
    : 'es';
}

export function getUserAlreadyLoggedInId(userInfo) {
  const result = {
    userId: '',
  };

  if (userInfo && userInfo.id) result.userId = userInfo.id;

  if (typeof document !== 'undefined') {
    const userIdHiddenField = document.getElementById('userId');
    if (userIdHiddenField) userIdHiddenField.value = JSON.stringify(result);
  }

  return result;
}

export function isServer() {
  return !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}
