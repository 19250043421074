const TOKENS = {
  local: 'f5c12069-ea27-460d-9108-40b72cd1e620',
  dev: '8947a23a-133a-49f2-b3ee-9f65876234a2',
  stg: '8c3b1a80-7faf-4e05-86f1-d18b4f5b8449',
  live: 'e25a4142-87ea-4ba4-aa10-8158a6dc9f4a',
  test: '',
};

const URL = {
  local: 'https://fwf-client-api-web.dev.peja.co',
  dev: 'http://fwf-client-api-web.stg.peja.co',
  stg: 'http://fwf-client-api-web.stg.peja.co',
  live: 'http://fwf-client-api-web.live.peja.co',
  test: '',
};

export function getFWFToken(environment) {
  if (TOKENS[environment] === undefined) return TOKENS.local;
  return TOKENS[environment];
}

export function getFWFUrl(environment) {
  if (URL[environment] === undefined) return URL.local;
  return URL[environment];
}
