import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLORS, HorizontalSeparator } from '@pedidosya/order-status-components';
import { getOrderIncident } from '@app/utils/order/getOrderIncident';
import { getOrderIncidentValue } from '@app/utils/order/getOrderIncidentValue';
import ORDER_TYPE from '@app/constants/order-type';
import ORDER_INCIDENT_CODE from '@app/constants/order-incident-code';
import messages from './messages';
import {
  CardContent,
  LeftColumn,
  CardFooter,
  CardContainer,
  CardTitle,
  CardDescription,
} from '@app/components/ui/molecules/Card';
import TimeLabel from '@app/components/ui/molecules/TimeLabel';
import Pill from '@app/components/ui/atoms/Pill';
import getDeliveryOrderMessageTranslationKeyByType from '@app/utils/order/getOrderPillMessageByType';
import { useTranslate } from '@app/providers/I18nProvider';
import { getIsPickupOrder } from '@app/utils/order/getIsPickupOrder';
import { getIsOrderReadyForPickup } from '@app/utils/order/getIsOrderReadyForPickup';

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

function LiveOrderCard({ order, onCardClicked, isDesktop }) {
  const { translate } = useTranslate();
  const onCardClick = useCallback(() => {
    onCardClicked({ order });
  }, [onCardClicked, order]);

  // Por ahora solo se mostraria este incident pero probablemente
  // cuando se iguale con nativas, hayan mas
  const timeIncidentText = getOrderIncidentValue(
    getOrderIncident(order, ORDER_INCIDENT_CODE.ORDER_DELAY),
    'pillText',
  );
  const showTag = timeIncidentText ? true : order?.type === ORDER_TYPE.VENDOR_DELIVERY;
  const pillTextKey = getDeliveryOrderMessageTranslationKeyByType(order?.type);
  const pillText = timeIncidentText ? timeIncidentText : translate(pillTextKey);

  const isPickup = getIsPickupOrder(order);
  const isOrderReadyToBePickedUp = getIsOrderReadyForPickup(order);
  let showTimeIcon = true;
  let timeLabelText = order?.time;

  if (isPickup && isOrderReadyToBePickedUp) {
    timeLabelText = `${translate(messages.orderNumber)} ${order.id}`;
    showTimeIcon = false;
  }
  return (
    <CardContainer
      id={`order_card_${order?.code}`}
      data-testid="card-container"
      onClick={onCardClick}
      isDesktop={isDesktop}
    >
      <CardContent>
        <LeftColumn>
          <CardTitle>{order?.vendor}</CardTitle>
          <CardDescription>{order?.description}</CardDescription>
        </LeftColumn>
      </CardContent>
      <HorizontalSeparator bgColor={COLORS.NIGHT_BLUE_2} />
      <CardFooter>
        <DetailsContainer>
          <TimeLabel showIcon={showTimeIcon}>{timeLabelText}</TimeLabel>
          {showTag && pillText && <Pill data-testid="delivery-pill">{pillText}</Pill>}
        </DetailsContainer>
      </CardFooter>
    </CardContainer>
  );
}

LiveOrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  onCardClicked: PropTypes.func.isRequired,
};

export default LiveOrderCard;
