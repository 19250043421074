import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Helmet } from 'react-helmet';
import { getHistory } from '@app/utils/history';
import { useWindow } from '@app/providers/WindowProvider';
import { FWFProvider } from '@app/providers/FWFProvider';
import { FLAGS_KEYS } from '@commons/fwf/constants';

import { usePublicEnv } from './providers/PublicEnv';
import MainRouter from './MainRouter';
import { useExternalServices } from '@app/providers/ExternalServiceProvider/ExternalServiceProvider';
import { getLogger } from '@app/logger';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(props) {
  const context = usePublicEnv();
  const { tracker } = useExternalServices();
  const globalObject = useWindow();

  const { shellData } = props;

  if (!globalObject) {
    return null;
  }

  return (
    <Router isServer={false} history={getHistory()}>
      <QueryClientProvider client={queryClient}>
        <Helmet title="Delivery de Comida Online - Elegí, Pedí y Ya | PedidosYa" />
        <Helmet
          meta={[
            {
              name: 'description',
              content: 'PedidosYa - Detalle de la orden',
            },
          ]}
        />
        {/* <FWFProvider flagList={FLAGS_KEYS}> */}
        <MainRouter
          tracker={tracker}
          context={context}
          isDesktop={shellData?.isDesktop}
          isHomeHelpCenterAccessEnabled={shellData?.isHomeHelpCenterAccessEnabled}
        />
        {/* </FWFProvider> */}
      </QueryClientProvider>
    </Router>
  );
}

App.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
};

export default App;
