import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePublicEnv } from '@app/providers/PublicEnv';
import { getLogger } from '@app/logger';

const SERVICE_KEY = '[AUTH]';
function Authentication({ children }) {
  const context = usePublicEnv();
  const [userLogged, setUserLogged] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = context?.user;
    if (user) {
      setUserLogged(true);
      getLogger().debug(`${SERVICE_KEY} user logged.`);
    } else {
      setUserLogged(false);
      getLogger().debug(`${SERVICE_KEY} user not logged.`);
    }
    setLoading(false);
  }, [context]);

  return children({ loading, userLogged });
}

Authentication.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Authentication;
