import { getRequestService } from '@app/services/request';
import isObject from '@commons/utils/object/isObject';
import pkg from '../../../../../package.json';
import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';

const LIVE_PUBLIC_URL = 'https://web-apps.pedidosya.com';

const isUnauthorizedError = (error) => {
  return isObject(error) && (error?.response?.status === 401 || error?.response?.status === 403);
};
const getErrorResponseBody = (error) => {
  return isObject(error) && (error?.response?.data || {});
};

const getApiBaseUrl = () => {
  return `/${pkg.name}/api`;
};

export const initPublicEnv = async () => {
  const response = new Response();

  const apiBaseUrl = getApiBaseUrl();
  const url = `${apiBaseUrl}/env`;
  try {
    const { data: context } = await getRequestService().get(url);

    response
      .setStatus(true)
      .setData({ ...context, apiBaseUrl: apiBaseUrl })
      .setCode(MICROSITE_STATE.SUCCESS);
  } catch (e) {
    if (isUnauthorizedError(e)) {
      response
        .setStatus(true)
        .setData(getErrorResponseBody(e))
        .setCode(MICROSITE_STATE.UNAUTHORIZED);
    } else {
      response.setStatus(false).setError(e.message).setCode(MICROSITE_STATE.ERROR);
    }
  }
  return response.toObject();
};
