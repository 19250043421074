import { isEmpty } from '@app/utils/string';
import isString from '@commons/utils/string/isString';

export const INTERNAL_ROUTES = {
  DEFAULT: '/',
};

export const EXTERNAL_ROUTES = {
  DESKTOP_LOGIN: '/login',
  MOBILE_LOGIN: 'https://www.pedidosya.com/user-account/integrated-register-login',
};

// Parent routes that user must be logged in to access them.
const PROTECTED_ROUTES = [];

export function isRouteProtected(route) {
  return isString(route) ? PROTECTED_ROUTES.some((r) => route.startsWith(r)) : false;
}

// Only backend
export function isDefault(route) {
  return route === INTERNAL_ROUTES.DEFAULT;
}
