import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import pkg from '../package.json';
import Root from './root.component';
import MicrositeError from './components/Microsite/MicrositeError';
import MicrositeWrapper from './components/Microsite/MicrositeWrapper';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  rootComponent: Root,
  errorBoundary: (error) => {
    const errorMessage = error && error?.message ? error?.message : error;
    return (
      <MicrositeWrapper>
        <MicrositeError
          subtitle={'Hubo un error al obtener los datos de tu órden.'}
          debugMessage={`ErrorBoundary: ${errorMessage}`}
        />
      </MicrositeWrapper>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
