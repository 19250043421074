import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '@commons/services/routes.service';
import { isEmpty } from '@app/utils/string';

const CrossDeviceRoutesContext = createContext({});

const CrossDeviceRoutesProvider = ({ children, device }) => {
  const [routes, setRoutes] = useState();
  const [lastDevice, setLastDevice] = useState(null);

  useEffect(() => {
    const currentDevice = device;
    if (!isEmpty(currentDevice) && lastDevice !== currentDevice) {
      const currentDevicePrefix = `${currentDevice.toUpperCase()}_`;
      const externalRoutes = Object.entries(EXTERNAL_ROUTES).reduce((accum, [key, value]) => {
        if (key.startsWith(currentDevicePrefix)) {
          accum[key.replace(currentDevicePrefix, '')] = value;
        }
        return accum;
      }, {});

      setLastDevice(device);
      setRoutes({ ...INTERNAL_ROUTES, ...externalRoutes });
    }
  }, [device, lastDevice]);

  return (
    <CrossDeviceRoutesContext.Provider value={routes}>{children}</CrossDeviceRoutesContext.Provider>
  );
};

CrossDeviceRoutesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRoutes = () => useContext(CrossDeviceRoutesContext);

export default CrossDeviceRoutesProvider;
