const isObject = require('@commons/utils/object/isObject').default;
const isString = require('@commons/utils/string/isString').default;
const tryToParseJSON = require('@commons/utils/string/tryToParseJSON').default;
const getNestedProp = require('@commons/utils/object/getNestedProp').default;

function parseErrorData(error) {
  const data = getNestedProp(error, 'response.data');
  if (!data) return null;
  if (isObject(data) || !isString(data)) return data;
  const { isValid, json } = tryToParseJSON(data);
  if (isValid) return json;
  return data;
}

function axiosError(error) {
  if (!error) return null;
  if (isString(error)) return error;

  const message = error.message || '';
  const url = getNestedProp(error, 'config.url') || '';
  const status = getNestedProp(error, 'response.status') || '';
  const errorData = parseErrorData(error);

  let response = {
    message,
    networkStatus: status,
    networkUrl: url,
  };

  if (isObject(errorData)) {
    response = { ...response, ...errorData };
  } else {
    response.data = errorData;
  }

  return response;
}

export default axiosError;
