import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import positiveOption from '@app/assets/icons/positiveOptionV2.svg';
import negativeOption from '@app/assets/icons/negativeOptionV2.svg';

import {
  CardContent,
  LeftColumn,
  CardContainer,
  CardTitle,
  CardDescription,
  RightColumn,
} from '@app/components/ui/molecules/Card';
import REVIEW_TYPE from '@app/constants/review-type';
import { useTranslate } from '@app/providers/I18nProvider';
import messages from './messages';

export const ImageOption = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0;
  }
`;

function ReviewPendingOrderCard({ order, onCardClicked, isDesktop }) {
  const { translate } = useTranslate();
  const updateReview = (initialReview = REVIEW_TYPE.NEUTRAL) => {
    onCardClicked({ order, riderReview: initialReview });
  };

  const onCardClick = useCallback(() => {
    onCardClicked({ order, riderReview: REVIEW_TYPE.NEUTRAL });
  }, [onCardClicked, order]);

  return (
    <CardContainer
      id={`order_card_${order?.code}`}
      data-testid="card-container"
      isDesktop={isDesktop}
    >
      <CardContent>
        <LeftColumn onClick={onCardClick}>
          <CardTitle>{order?.vendor}</CardTitle>
          <CardDescription>{translate(messages.riderReviewTitle)}</CardDescription>
        </LeftColumn>
        <RightColumn centered>
          <ImageOption
            alt="thumbs-down-review"
            src={negativeOption}
            onClick={() => updateReview(REVIEW_TYPE.NEGATIVE)}
          />
          <ImageOption
            alt="thumbs-up-review"
            src={positiveOption}
            onClick={() => updateReview(REVIEW_TYPE.POSITIVE)}
          />
        </RightColumn>
      </CardContent>
    </CardContainer>
  );
}

ReviewPendingOrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  onCardClicked: PropTypes.func.isRequired,
};

export default ReviewPendingOrderCard;
