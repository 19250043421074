import React from 'react';

import { theme } from '@appscore/web-components';
import { ThemeProvider } from '@app/providers/ThemeProvider';
import WindowProvider from '@app/providers/WindowProvider';
import ErrorBoundary from '@app/components/ErrorBoundary';

const MicrositeWrapper = ({ children }) => {
  return (
    <ErrorBoundary>
      <WindowProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </WindowProvider>
    </ErrorBoundary>
  );
};

export default MicrositeWrapper;
