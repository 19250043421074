import { isEmpty } from '@app/utils/string';

export function getOrderIncidentValue(incident, parameterKey) {
  if (incident && Array.isArray(incident?.params) && !isEmpty(parameterKey)) {
    const key = parameterKey.trim().toLowerCase();
    const shearchedIncidentParameter = incident?.params
      .filter((parameter) => parameter && !isEmpty(parameter.key))
      .find((parameter) => parameter.key.toLowerCase() === key);

    return shearchedIncidentParameter && 'value' in shearchedIncidentParameter
      ? shearchedIncidentParameter.value
      : null;
  }

  return null;
}
