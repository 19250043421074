import styled, { css } from 'styled-components';
// import { CARD_WIDTH_UNIQUE_ORDER, CARD_WIDTH_MULTIPLE_ORDERS } from './constants';

export const CardContent = styled.div`
  display: flex;
  padding: 12px 12px 16px 12px;
  align-items: stretch;
  flex: 1;
  min-height: 56px;
`;

const ColumnProps = css`
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : 'auto')};
`;

export const LeftColumn = styled.div`
  ${ColumnProps}
  flex-grow: 1;
`;
export const RightColumn = styled.div`
  display: flex;
  flex-direction: row;
  ${ColumnProps}
  padding-left: 16px;
  ${(props) => (props.separator ? `border-left: 1px solid rgba(239, 237, 240, 0.3);` : '')}
  ${(props) =>
    props.centered
      ? `
            justify-content: center;
            align-items: center;
          `
      : ''}
`;

export const CardFooter = styled.div`
  padding: 10px 12px 10px 16px;
  min-height: 18px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* me pidieron que lo haga fijo yo habia usado min-height*/
  min-height: 120px;
  width: ${(props) => (props.totalOrders === 1 ? '100%' : '84%')};
  width: 87%;
  max-width: 371px;
  min-width: 300px;
  border-radius: 6px;
  background-color: #100522;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      max-width: 346px;
      border: 1px solid #dbdade;
    `}

  @media screen and (min-height: 320px) {
    min-height: 124px;
    ${CardContent} {
      padding: 12px 16px 16px 16px;
    }
    ${CardFooter} {
      padding: 10px 12px 10px 16px;
    }
  }

  &:only-of-type {
    width: 100%;
  }
`;

export const CardLabel = styled.div`
  font-family: 'Segma';
  font-size: 20px;
  margin: 0 0px 12px 0px;
  font-weight: 600;
`;

export const CardTitle = styled.div`
  font-family: 'Segma';
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  word-break: break-word;
`;

export const CardDescription = styled.div`
  font-family: 'Segma';
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  font-weight: 600;
`;
