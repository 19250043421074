import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
  min-height: 157px;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      justify-content: flex-start;
    `}

  > * {
    font-size: 20px;
  }
`;
export const HelpCenterContentContainer = styled.div`
  display: flex;
  margin-left: 0px;

  padding: 3px 4px;
  border-radius: 4px;

  &:active {
    background-color: #10042314;
  }

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-left: 48px;
      padding: 3px 4px;
      border-radius: 4px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    `}

  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const HelpCenterDesktopImage = styled.img`
  width: 17px;
  height: 17px;
`;
