import { createLoggerInstance, getLogger } from '@app/logger';
import { initRequestService } from '@app/services/request';
import { setEnvironment } from '@app/utils/environment';
import isObject from '@commons/utils/object/isObject';
import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';
import { initFWFService } from './init-fwf';
import { initPublicEnv } from './init-public-env';
import { initTrackerService } from './init-tracker';
import { initTranslationMessages } from './init-translation-messages';
const SERVICE_KEY = `[INIT]`;

export async function initMicrositeData(shellData) {
  const response = new Response();

  let context = {};
  let messages = {};

  // this should be first because its used to make requests
  initRequestService({ defaultHeaders: shellData?.appHeaders || {} });

  const contextResponse = await initPublicEnv();

  if (contextResponse.status && isObject(contextResponse.data)) {
    context = contextResponse.data;
  } else {
    response.setStatus(false).setCode(contextResponse.code).setError(contextResponse.error);
    return response.toObject();
  }

  if (!context || !isObject(context)) {
    response
      .setStatus(false)
      .setCode(MICROSITE_STATE.ERROR)
      .setError(`[initPublicEnv] Invalid public env: '${context}'`);
    return response.toObject();
  }

  try {
    const { trackerPromise, fwfPromise } = shellData;

    setEnvironment(context?.environment);

    // Client logger initialization
    await createLoggerInstance({
      environment: context?.environment,
      tokenType: context?.app?.auth?.tokenType,
      countryId: context?.app?.country?.id,
      countryShortName: context?.app?.country?.shortName,
      origin: context?.app?.origin,
      appVersion: context?.app?.appVersion,
      platformKey: context?.app?.platformKey,
      locale: context?.locale,
      device: context?.device,
    });

    messages = await initTranslationMessages(context?.locale);

    const logger = getLogger();
    logger.debug(`${SERVICE_KEY} intanciating external services`);

    const FWFResponse = await initFWFService(fwfPromise, context);

    if (!FWFResponse.status) {
      response.setError(FWFResponse.error);
      response.setCode(MICROSITE_STATE.ERROR);
      return response.toObject();
    }

    const TrackerResponse = await initTrackerService(trackerPromise);

    if (!TrackerResponse.status) {
      response.setError(TrackerResponse.error);
      response.setCode(MICROSITE_STATE.ERROR);
      return response.toObject();
    }

    const externalServices = {
      fwf: FWFResponse.data,
      tracker: TrackerResponse.data,
    };

    logger.debug(`${SERVICE_KEY} external services instanciated successfully`);

    response.setStatus(true).setCode(MICROSITE_STATE.SUCCESS).setData({
      shellData,
      context,
      externalServices,
      messages,
    });
  } catch (error) {
    const logger = getLogger();
    if (logger) {
      logger.error(`${SERVICE_KEY} microsite data error: ${error.message}`);
    }
    response.setStatus(false).setCode(MICROSITE_STATE.ERROR).setError(error.message);
  }

  return response.toObject();
}
