import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { COLORS, HorizontalSeparator, Image } from '@pedidosya/order-status-components';
import orderTicket from '@app/assets/icons/orderTicketV2.svg';

import {
  CardContent,
  LeftColumn,
  CardFooter,
  CardContainer,
  CardTitle,
  CardDescription,
  RightColumn,
} from '@app/components/ui/molecules/Card';
import TimeLabel from '@app/components/ui/molecules/TimeLabel';

function CancelledOrderCard({ order, onCardClicked, isDesktop }) {
  const onCardClick = useCallback(() => {
    onCardClicked({ order });
  }, [onCardClicked, order]);

  let showTimeIcon = false;
  let timeLabelText = order?.time;

  return (
    <CardContainer
      id={`order_card_${order?.code}`}
      data-testid="card-container"
      onClick={onCardClick}
      isDesktop={isDesktop}
    >
      <CardContent>
        <LeftColumn>
          <CardTitle>{order?.vendor}</CardTitle>
          <CardDescription>{order?.description}</CardDescription>
        </LeftColumn>
        <RightColumn>
          <Image data-testid="ticket-icon" width={36} height={51} src={orderTicket} />
        </RightColumn>
      </CardContent>
      <HorizontalSeparator bgColor={COLORS.NIGHT_BLUE_2} />
      <CardFooter>
        <TimeLabel showIcon={showTimeIcon}>{timeLabelText}</TimeLabel>
      </CardFooter>
    </CardContainer>
  );
}

CancelledOrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  onCardClicked: PropTypes.func.isRequired,
};

export default CancelledOrderCard;
