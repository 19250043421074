import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const promiseAllSettled = (promises) => {
  return Promise.all(
    promises.map(function (promise) {
      return promise
        .then(function (value) {
          return { state: 'fulfilled', value: value };
        })
        .catch(function (reason) {
          return { state: 'rejected', reason: reason };
        });
    }),
  );
};

import { getClientInstance } from '@commons/fwf/instance';
export const FWFContext = React.createContext(null);

export const useFWF = () => useContext(FWFContext);

async function getFlag(flagKey) {
  const fwf = await getClientInstance();
  return fwf.getFlag(flagKey);
}

async function getFlags(flagList) {
  const fwf = await getClientInstance();

  const promises = flagList.map(async (flagKey) => fwf.getFlag(flagKey));
  return promiseAllSettled(promises).then((responses) => {
    const newFlagValues = {};
    responses.forEach((response) => {
      if (response.status === 'fulfilled') {
        newFlagValues[response.value.keyName] = response.value;
      }
    });
    return newFlagValues;
  });
}

const FWFProvider = ({ children, flagList }) => {
  const [data, setData] = useState({});

  const getFlagValue = useCallback(
    (flagKey) => {
      if (data[flagKey]) {
        return Promise.resolve(data[flagKey]);
      }
      return getFlag(flagKey).then((flag) => {
        setData((prevData) => ({ ...prevData, [flag.keyName]: flag }));
        return flag;
      });
    },
    [data],
  );

  const state = useMemo(
    () => ({
      flags: data,
      getFlagValue,
    }),
    [data, getFlagValue],
  );

  useEffect(() => {
    let isMounted = true;
    getFlags(flagList).then((newFlags) => {
      if (isMounted) {
        setData((prevData) => ({
          ...prevData,
          ...newFlags,
        }));
      }
    });

    return () => {
      isMounted = false;
    };
  }, [flagList]);

  return <FWFContext.Provider value={state}>{children}</FWFContext.Provider>;
};

FWFProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flagList: PropTypes.array.isRequired,
};

export default FWFProvider;
