import fetchUserInProgressOrders from '@app/services/fetchUserInProgressOrders';
import React, { useCallback, useEffect } from 'react';
import UserOrdersData from './components/UserOrdersData';
import {
  FONT_TYPE,
  FONT_VARIATION,
  COLORS,
  Slider,
  Text,
  TEXT_DECORATION,
} from '@pedidosya/order-status-components';
import MicrositeError from '@app/components/Microsite/MicrositeError';
import { getActionRiderReview } from '@app/utils/order/getActionRiderReview';
import { getRiderTime } from '@app/utils/order/getRiderTime';
import ReviewPendingOrderCardTracking from '@app/components/ui/molecules/ReviewPendingOrderCard/ReviewPendingOrderCardTracking';
import ReviewPendingOrderCard from '@app/components/ui/molecules/ReviewPendingOrderCard';
import LiveOrderCard from '@app/components/ui/molecules/LiveOrderCard';
import CancelledOrderCard from '@app/components/ui/molecules/CancelledOrderCard';
import SliderSkeleton from '@app/components/ui/molecules/SliderSkeleton';
import helpCenterIconWhite from './assets/helpCenterIconWhite.svg';
import helpCenterIconBlack from './assets/helpCenterIconBlack.svg';
import {
  HeaderContainer,
  HelpCenterContentContainer,
  HelpCenterDesktopImage,
  PageWrapper,
} from './UserOrderCardsPage.styles';

export const REQUEST_UPDATE_INTERVAL = 30000;
export const DEFAULT_ORIGIN = 'shop_list';

const getRiderReviewDateInfo = (order) => {
  const riderReviewAction = getActionRiderReview(order?.actions);
  return riderReviewAction ? getRiderTime(riderReviewAction) : null;
};

const UserOrderCardsPage = ({ tracker, context, isDesktop, isHomeHelpCenterAccessEnabled }) => {
  /* Track Online Help Shown Event */
  useEffect(() => {
    if (isHomeHelpCenterAccessEnabled) {
      tracker?.track('online_help.shown', {
        eventOrigin: 'home',
        screenType: 'home_screen',
      });
    }
  }, [isHomeHelpCenterAccessEnabled]);

  const fetcherService = useCallback(
    () =>
      fetchUserInProgressOrders(context.apiBaseUrl, {
        countryId: context?.country?.id,
        userId: context?.userId,
      }).then((response) => {
        if (response.error) {
          throw new Error(response.error);
        }
        return response.data;
      }),
    [context],
  );

  const goToRiderReviewPage = ({ order, riderReview }) => {
    const riderTime = getRiderReviewDateInfo(order);
    let url = `/rider-review/${order?.id}?origin=${DEFAULT_ORIGIN}&riderReview=${riderReview}&orderType=${order.type}&restaurantName=${order?.vendor}`;

    if (riderTime) {
      url += `&dateInfo=${riderTime}`;
    }
    window.location.href = encodeURI(url);
  };

  const goToOrderStatus = ({ order }) => {
    window.location.href = `/order-state?orderId=${order.id}&origin=${DEFAULT_ORIGIN}&c=${context?.app?.country?.shortName}`;
  };

  /* Track Online Help Click Event */
  const trackOnlineHelpClick = () => {
    tracker?.track('online_help.clicked', {
      eventOrigin: 'home',
      screenType: 'home_screen',
    });
  };

  const goToHelpCenter = () => {
    window.location.href = '/customer-service?page_id=ordersList&origin=home';
  };

  const handleClickHelpCenter = () => {
    trackOnlineHelpClick();
    goToHelpCenter();
  };

  return (
    <UserOrdersData fetchService={fetcherService} refetchMilliseconds={REQUEST_UPDATE_INTERVAL}>
      {({ error, loading, orders }) => {
        if (error) {
          return (
            <PageWrapper data-testid="error">
              <MicrositeError />
            </PageWrapper>
          );
        }

        if (loading) {
          if (isDesktop) return null;
          return (
            <PageWrapper data-testid="loader">
              <SliderSkeleton />
            </PageWrapper>
          );
        }

        if (orders.all.length === 0) {
          return null;
        }

        const inProgressTitle = orders.all.length === 1 ? 'Pedido en curso' : 'Pedidos en curso';
        const sliderTitleProps = {
          font: FONT_TYPE.TERTIARY,
          fontVariation: FONT_VARIATION.largeSemiBold,
          color: isDesktop ? `${COLORS.WHITE}` : '',
        };
        return (
          <PageWrapper>
            <HeaderContainer isDesktop={isDesktop}>
              <Text {...sliderTitleProps}>{inProgressTitle}</Text>
              {isHomeHelpCenterAccessEnabled ? (
                <HelpCenterContentContainer isDesktop={isDesktop} onClick={handleClickHelpCenter}>
                  <HelpCenterDesktopImage
                    src={isDesktop ? helpCenterIconWhite : helpCenterIconBlack}
                  />
                  <Text
                    font={FONT_TYPE.TERTIARY}
                    fontVariation={FONT_VARIATION.smallRegular}
                    textDecoration={TEXT_DECORATION.UNDERLINE}
                    color={isDesktop ? `${COLORS.WHITE}` : ''}
                  >
                    Ayuda en línea
                  </Text>
                </HelpCenterContentContainer>
              ) : null}
            </HeaderContainer>
            <Slider
              itemsPerView={0}
              itemMinWidth={isDesktop ? 348 : 343}
              spaceBetweenItems={isDesktop ? 12 : 6}
              showControls={false}
            >
              {orders.pendingReview.length
                ? orders.pendingReview.map((order) => (
                    <ReviewPendingOrderCardTracking
                      tracker={tracker}
                      key={order.id}
                      order={order}
                      onCardClicked={goToRiderReviewPage}
                    >
                      {(props) => <ReviewPendingOrderCard {...props} isDesktop={isDesktop} />}
                    </ReviewPendingOrderCardTracking>
                  ))
                : null}
              {orders.cancelled.length
                ? orders.cancelled.map((order) => (
                    <CancelledOrderCard
                      key={order.id}
                      order={order}
                      onCardClicked={goToOrderStatus}
                      isDesktop={isDesktop}
                    />
                  ))
                : null}
              {orders.live.length
                ? orders.live.map((order) => (
                    <LiveOrderCard
                      key={order.id}
                      order={order}
                      onCardClicked={goToOrderStatus}
                      isDesktop={isDesktop}
                    />
                  ))
                : null}
            </Slider>
          </PageWrapper>
        );
      }}
    </UserOrdersData>
  );
};

export default UserOrderCardsPage;
