import { createBrowserHistory, createMemoryHistory } from 'history';

let history;

export function createServerHistory({ initialEntries }) {
  return createMemoryHistory({ initialEntries });
}

export function createClientHistory() {
  history = createBrowserHistory();
  return history;
}

export function getHistory() {
  return history;
}
