import ServiceResponse from '@commons/services/models/ServiceResponse';
import { getRequestService } from './request';

const fetchUserInProgressOrders = async (apiBaseUrl, { countryId, userId }) => {
  try {
    const response = await getRequestService().get(
      `${apiBaseUrl}/v2/country/${countryId}/user/${userId}/orders`,
    );
    return new ServiceResponse({ data: response.data });
  } catch (error) {
    return new ServiceResponse({
      error,
    });
  }
};

export default fetchUserInProgressOrders;
