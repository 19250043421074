import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clock from '@app/assets/icons/clockV2.svg';

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TimeIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const Time = styled.div`
  font-family: 'Segma';
  font-size: 12px;
  line-height: 16px;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimeLabel = ({ children, showIcon }) => {
  return (
    <TimeContainer>
      {showIcon && <TimeIcon src={clock} />}
      <Time>{children}</Time>
    </TimeContainer>
  );
};

TimeLabel.propTypes = {
  children: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
};

TimeLabel.defaultProps = {
  showIcon: true,
};

export default TimeLabel;
