import React, { useEffect, useState } from 'react';
import isObject from '@commons/utils/object/isObject';
import { initMicrositeData } from './utils/microsite-data';
import MicrositeLoader from './MicrositeLoader';
import MicrositeError from './MicrositeError';
import MicrositeWrapper from './MicrositeWrapper';
import { PublicEnvProvider } from '@app/providers/PublicEnv';
import I18nProvider from '@app/providers/I18nProvider';
import CrossDeviceRoutesProvider from '@app/providers/CrossDeviceRoutesProvider';
import Authentication from './components/Authentication';
import ExternalServiceProvider from '@app/providers/ExternalServiceProvider';
import { getLogger } from '@app/logger';

const MicrositeInitializer = ({ shellData, AppComponent }) => {
  const [micrositeData, setMicrositeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    initMicrositeData(shellData)
      .then((response) => {
        if (response.status) {
          setMicrositeData(response.data);
        } else {
          setError(response.error);
        }
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [shellData]);

  if (loading) {
    if (shellData?.isDesktop) {
      return null;
    }
    return <MicrositeLoader />;
  }

  let StateComponent = null;
  if (error || !micrositeData) {
    const errorMessage = `Error initializing microsite '${
      isObject(error) ? error.toString() : error
    }'`;
    const logger = getLogger();
    if (logger) {
      logger.error(errorMessage);
    }
    StateComponent = <MicrositeError debugMessage={errorMessage} />;
  } else {
    StateComponent = (
      <Authentication>
        {({ loading, userLogged }) => {
          if (loading && !shellData?.isDesktop) return <MicrositeLoader />;
          if (!userLogged) {
            getLogger().info('User not logged.');
            return null;
          }
          return (
            <ExternalServiceProvider externalServices={micrositeData?.externalServices}>
              <AppComponent {...micrositeData} />
            </ExternalServiceProvider>
          );
        }}
      </Authentication>
    );
  }

  const debugMode = !micrositeData?.shellData?.isProduction;
  return (
    <MicrositeWrapper>
      <PublicEnvProvider
        debugMode={debugMode}
        context={micrositeData?.context}
        loader={!shellData?.isDesktop && <MicrositeLoader />}
      >
        <I18nProvider locale={micrositeData?.context?.locale} messages={micrositeData?.messages}>
          <CrossDeviceRoutesProvider device={micrositeData?.context?.device}>
            {StateComponent}
          </CrossDeviceRoutesProvider>
        </I18nProvider>
      </PublicEnvProvider>
    </MicrositeWrapper>
  );
};

export default MicrositeInitializer;
