import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from '@pedidosya/order-status-components';
import { isCancelled } from '@app/utils/order/getIsCancelledOrder';
import { getIsEnabledRiderReview } from '@app/utils/order/getIsEnabledRiderReview';

/** Group orders by features */
const isPending = (order) => !isCancelled(order) && getIsEnabledRiderReview(order?.actions);
const isLive = (order) => !isCancelled(order) && !getIsEnabledRiderReview(order?.actions);
const filterOrderTypes = (orderList) => {
  const pendingReview = orderList.filter(isPending);
  const cancelled = orderList.filter(isCancelled);
  const live = orderList.filter(isLive);
  const all = [...pendingReview, ...cancelled, ...live];

  return {
    pendingReview,
    cancelled,
    live,
    all,
  };
};

const MIN_MILLIS_TO_REFETCH = 500;

function isValidRefetchInterval(intervalMilliseconds) {
  return intervalMilliseconds >= MIN_MILLIS_TO_REFETCH;
}

const UserOrdersData = ({ fetchService, children, refetchMilliseconds }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refetching, setRefetching] = useState(false);
  const [data, setData] = useState(null);

  const callService = () => {
    if (data !== null) {
      setRefetching(true);
    } else {
      setLoading(true);
    }
    fetchService()
      .then((response) => setData(filterOrderTypes(response)))
      .catch((e) => setError(e.message))
      .finally(() => {
        setLoading(false);
        setRefetching(false);
      });
  };

  useEffect(() => {
    callService();
  }, []);

  useInterval(
    callService,
    data?.live?.length > 0 && isValidRefetchInterval(refetchMilliseconds) ? refetchMilliseconds : 0,
  );

  return children({ error, loading, refetching, orders: data });
};

UserOrdersData.propTypes = {
  children: PropTypes.func.isRequired,
  fetchService: PropTypes.func.isRequired,
  refetchMilliseconds: PropTypes.number,
};

UserOrdersData.defaultProps = {
  refetchMilliseconds: 0,
};

export default UserOrdersData;
